import Vue from 'vue'
import { isValidCpf } from '@brazilian-utils/validators'
import moment from 'moment'
import cardValidator from 'card-validator'

Vue.prototype.$rules = {
  required: v => {
    return !!v || (typeof v === 'number' && v !== undefined && v !== null) || 'Campo obrigatório'
  },
  email: v => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || 'E-mail inválido',
  confirmPassword: (password1, password2) => {
    return () => {
      return (password1 && password2 && password1 === password2) || 'Os campos de senha precisam ser iguais'
    }
  },
  cpf:
     v => {
       return isValidCpf(v) || 'CPF inválido'
     },
  date: v => {
    const date = moment(v, 'DD/MM/YYYY')
    return (v && date.isValid() && date.isBefore(new Date())) || 'Data inválida'
  },
  name: v => /[A-Za-z]* .{2}/.test(v) || 'Insira o nome com sobrenome',
  nameAbbreviated: v => /[A-Za-z]* .{2}/.test(v) || 'Insira o nome com sobrenome abreviando os nomes do meio',
  length5: v => (!!v && v.length >= 5) || 'Mínimo 5 caracteres',
  length6: v => (!!v && v.length >= 6) || 'Mínimo 6 caracteres',
  month: v => {
    return (!!v && v.length === 2 && moment(v, 'MM').isValid()) || 'Mês inválido'
  },
  year: v => {
    return (!!v && v.length === 4 && moment(v, 'YYYY').isValid()) || 'Ano inválido'
  },
  creditCardNumber: value => {
    const numberValidator = cardValidator.number(value)
    return (numberValidator.isPotentiallyValid || numberValidator.isValid) || 'Número de cartão inválido'
  },
  expirationDateCard: value => {
    const expirationValidator = cardValidator.expirationDate(value)
    return (expirationValidator.isPotentiallyValid || expirationValidator.isValid) || 'Data inválida'
  },
  cvvCard: value => {
    const cvvValidator = cardValidator.cvv(value, 4)
    return (cvvValidator.isPotentiallyValid || cvvValidator.isValid) || 'Código de segurança inválido'
  },
  document: v => {
    return v.length <= 20 || 'Máximo 20 caracteres'
  },
  passwordLength: v => {
    return (v.length >= 6 && v.length <= 20) || 'Mínimo 6 e máximo 18 caracteres'
  },
  rg: v => (!/[^A-Z0-9]/ig.test(v) || 'Este campo não pode conter espaços e caracteres especiais'),
  compareBirthdate: (birthdate, ageLimit) => {
    if (ageLimit) {
      const birthdateLimit = moment().subtract(ageLimit, 'years')
      return (moment(birthdate, 'DD/MM/YYYY').isAfter(birthdateLimit)) || 'Idade não permitida para este evento.'
    }
    return true
  }
}
