import { postRefund } from '@/services/refunds-service'
import { getTermData } from '../services/users-subscriptions-service'

const state = {
  step: 0,
  totalSteps: 3,
  dialog: false,
  userSubscriptionId: '',
  paymentMethod: '',
  refund: {},
  termData: {
    user: {
      userData: {}
    },
    subscription: {
      amount: 0
    }
  }
}

const mutations = {
  setStep (state, value) {
    state.step = value
  },
  setDialog (state, value) {
    state.dialog = value
  },
  setSuccess (state, value) {
    state.success = value
  },
  setUserSubscriptionId (state, value) {
    state.userSubscriptionId = value
  },
  setPaymentMethod (state, value) {
    state.paymentMethod = value
  },
  setPropertyRefund (state, { property, value }) {
    state.refund[property] = value
  },
  clearData (state) {
    state.dialog = false
    state.step = 0
  },
  setTermData (state, value) {
    state.termData = value
  }
}

const actions = {
  start ({ commit }, { userSubscriptionId, paymentMethod }) {
    commit('setUserSubscriptionId', userSubscriptionId)
    commit('setPaymentMethod', paymentMethod)
    commit('setDialog', true)
  },
  async searchTermData ({ commit }, value) {
    const { data } = await getTermData(value)
    commit('setTermData', data)
  },
  async submitBoletoToApi ({ state, commit }, formData) {
    try {
      await postRefund(state.userSubscriptionId, formData)
    } catch (error) {
      commit('setSuccess', false)
    }
  },
  async submitCreditCardToApi ({ state, commit }) {
    try {
      await postRefund(state.userSubscriptionId)
    } catch (error) {
      commit('setSuccess', false)
    }
  },
  nextStep ({ getters, dispatch, commit, state }) {
    if (getters.isPaymentCard && getters.isTermStep) {
      dispatch('submitCreditCardToApi')
      commit('setStep', state.step + 2)
    } else {
      commit('setStep', state.step + 1)
    }
  },
  prevStep ({ state, commit }) {
    commit('setStep', state.step - 1)
  },
  finish ({ commit }) {
    commit('clearData')
  }
}

const getters = {
  isPaymentCard (state) {
    return state.paymentMethod === 'Cartão de crédito'
  },
  isTermStep (state) {
    return state.step === 1
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
