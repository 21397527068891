import Vue from 'vue'
import moment from 'moment'

const currency = value => {
  return value !== null && value !== undefined ? Number(value).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : value
}
const maxCharacters = (value, limite) => {
  return value.length > limite ? value.slice(0, limite).concat('...') : value
}

const formatDate = (value) => {
  return value ? moment(value, 'YYYY-MM-DD').format('DD/MM/YYYY') : ''
}

const maskDocument = (value, nationality) => {
  return value && nationality === 'BRASILEIRA' ? value.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4') : value
}

const paymentStatus = (value) => {
  const mapStatus = new Map([
    ['EMITIDO', 'AGUARDANDO PAGAMENTO'],
    ['CANCELADO', 'CANCELADO/VENCIDO']
  ])
  let filtered
  if (value) {
    filtered = mapStatus.get(value)
  }
  return filtered || value
}

export { formatDate }

Vue.filter('currency', currency)
Vue.filter('maxCharacters', maxCharacters)
Vue.filter('formatDate', formatDate)
Vue.filter('maskDocument', maskDocument)
Vue.filter('paymentStatus', paymentStatus)
