const state = {
  active: false,
  lock: false
}

const mutations = {
  start (state) {
    state.active = true
  },

  stop (state) {
    state.active = false
  },

  lock (state) {
    state.lock = true
  },

  unlock (state) {
    state.lock = false
  }
}

const actions = {
  async start (context) {
    await context.commit('start')
  },

  async stop (context) {
    await context.commit('stop')
  },

  async lock ({ commit }) {
    await commit('lock')
  },

  async unlock ({ commit }) {
    await commit('unlock')
  }
}

const getters = {
  isActive (state) {
    return state.active && !state.lock
  },
  isLocked (state) {
    return state.lock
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
