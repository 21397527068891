<template>
  <div>
    <label
      class="mt-2"
    >{{ label }}</label>
    <!-- v-if for maskParam / issue on lib -->
    <input
      v-if="mask"
      :id="id"
      v-mask="mask"
      :type="type"
      name="input"
      :class="{'form-control':true, 'input-error': !valid}"
      :value="value"
      :minlength="minlength"
      :maxlength="maxlength"
      :disabled="disabled"
      :placeholder="placeholder"
      @input="handleInput"
      @blur="event => $emit('blur', event)"
    >
    <input
      v-else
      :id="id"
      :type="type"
      name="input"
      :class="{'form-control':true, 'input-error': !valid}"
      :value="value"
      :minlength="minlength"
      :maxlength="maxlength"
      :disabled="disabled"
      :placeholder="placeholder"
      @input="handleInput"
      @blur="event => $emit('blur', event)"
    >
    <div
      v-show="info"
      class="help-block with-errors"
    >
      {{ info }}
    </div>
    <transition name="fade">
      <div
        v-show="error"
        class="help-block with-errors text-danger"
      >
        {{ error }}
      </div>
    </transition>
  </div>
</template>

<script>
import KosmoRules from './KosmoRules'
export default {
  mixins: [
    KosmoRules
  ],
  props: {
    label: String,
    type: { type: String, default: 'text' },
    info: String,
    value: String,
    mask: [String, Array],
    minlength: [String, Number],
    maxlength: [String, Number],
    id: String,
    disabled: { type: Boolean, default: false },
    placeholder: String,
    masked: { type: Boolean, default: false }
  },
  watch: {
    value (value) {
      this.handleValue(value)
    }
  },
  methods: {
    handleInput (e) {
      const value = e.target.value
      this.handleValue(value)
    },
    handleValue (value) {
      // Remove os símbolos da máscara
      if (this.mask && !this.masked) {
        value = this.removeMask(value)
      }
      this.validate(value)
      this.$emit('input', value)
    },

    removeMask (value) {
      if (value) {
        value = value.replace(/[^a-zA-Z0-9 ]/g, '')
        return value
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.input-error {
  border: 2px solid #dc3545;
}
</style>
