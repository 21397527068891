import Vue from 'vue'

const errorHandler = (error) => {
  if (error.response && error.response.status === 400) {
    Vue.prototype.$swal(error.response.data.message, '', 'error')
  } else {
    console.error(error)
    Vue.prototype.$swal('Erro interno', 'Tente novamente', 'error')
  }
}

Vue.prototype.$errorHandler = errorHandler
