import Vue from 'vue'
import KosmoInput from './KosmoInput'
import KosmoForm from './KosmoForm'
import KosmoSelect from './KosmoSelect'
import KosmoTextarea from './KosmoTextarea'
import CorridaInputDocument from './CorridaInputDocument'
import CorridaSelectNationality from './CorridaSelectNationality'

Vue.component('KosmoInput', KosmoInput)
Vue.component('KosmoForm', KosmoForm)
Vue.component('KosmoSelect', KosmoSelect)
Vue.component('KosmoTextarea', KosmoTextarea)
Vue.component('CorridaInputDocument', CorridaInputDocument)
Vue.component('CorridaSelectNationality', CorridaSelectNationality)
