<template>
  <div id="app">
    <router-view />
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      :opacity="0.5"
      background-color="black"
    >
      <div class="row d-flex justify-content-center">
        <fulfilling-bouncing-circle-spinner
          :animation-duration="4000"
          :size="80"
          color="white"
        />
      </div>
    </loading>
  </div>
</template>

<script>
import 'vue-loading-overlay/dist/vue-loading.css'
import { FulfillingBouncingCircleSpinner } from 'epic-spinners'
// Import component
import Loading from 'vue-loading-overlay'
// Import stylesheet
export default {
  components: {
    'fulfilling-bouncing-circle-spinner': FulfillingBouncingCircleSpinner,
    Loading
  },
  computed: {
    isLoading () {
      return this.$store.getters['loading/isActive']
    }
  }
}
</script>

<style lang="stylus">
.modal-content {
  background-color: rgba(0, 0, 0, 0.0001) !important;
}
</style>
