<template>
  <div>
    <label
      class="mt-2"
      for="input_form"
    >{{ label }}</label>
    <textarea
      :value="value"
      :rows="rows"
      :class="{'form-control':true, 'input-error': !valid}"
      @input="handleInput"
    />
    <transition name="fade">
      <div
        v-show="error"
        class="help-block with-errors text-danger"
      >
        {{ error }}
      </div>
    </transition>
  </div>
</template>

<script>
import KosmoRules from './KosmoRules'
export default {
  mixins: [KosmoRules],
  props: {
    value: { type: String },
    rows: { type: String },
    label: String
  },
  methods: {
    handleInput (e) {
      const value = e.target.value
      this.handleValue(value)
    },
    handleValue (value) {
      this.validate(value)
      this.$emit('input', value)
    }
  }
}
</script>

<style lang="stylus" scoped>
.input-error {
  border: 2px solid red;
}
</style>
