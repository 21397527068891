<template>
  <div>
    <label
      class="mt-2"
      for="select_form"
    >
      {{ label }}
      <i
        v-if="info"
        :id="uid"
        class="fa fa-info-circle"
      >
        <b-tooltip :target="uid">{{ info }}</b-tooltip>
      </i>
    </label>
    <b-form-select
      :class="{'form-control':true, 'input-error': !valid}"
      :value="value"
      :options="items"
      plain
      :value-field="valueField"
      :text-field="textField"
      :disabled="disabled"
      @input="handleInput"
    />
    <transition name="fade">
      <div
        v-show="error"
        class="help-block with-errors text-danger"
      >
        {{ error }}
      </div>
    </transition>
  </div>
</template>

<script>
import KosmoRules from './KosmoRules'
export default {
  mixins: [
    KosmoRules
  ],
  props: {
    value: [String, Number],
    items: Array,
    label: String,
    'value-field': String,
    'text-field': String,
    info: String,
    disabled: Boolean
  },
  computed: {
    uid () {
      return this._uid.toString()
    }
  },
  methods: {
    handleInput (value) {
      this.validate(value)
      this.$emit('input', value)
    }
  }
}
</script>

<style lang="stylus" scoped>
.input-error {
  border: 2px solid red;
}
</style>
