import Vue from 'vue'
import Vuex from 'vuex'
import loading from '@/store/loading-module'
import auth from '@/store/auth-module'
import subscription from '@/store/subscription-module'
import refund from '@/store/refund-module'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

const config = {
  modules: {
    loading,
    auth,
    subscription,
    refund
  },
  strict: debug
}

export default new Vuex.Store(config)
