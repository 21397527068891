import axios from 'axios'
import store from '@/store/index'
import getEnv from '@/config/env'
const api = () => {
  const token = store.getters['auth/token']
  const api = axios.create({
    baseURL: getEnv('VUE_APP_API'),
    headers: { Authorization: `Bearer ${token}` },
    timeout: 10000
  })

  api.interceptors.request.use(
    conf => {
      store.dispatch('loading/start')
      return Promise.resolve(conf)
    },
    error => {
      store.dispatch('loading/stop')
      return Promise.reject(error)
    }
  )

  api.interceptors.response.use(
    conf => {
      store.dispatch('loading/stop')
      return Promise.resolve(conf)
    },
    error => {
      // if (error.response.status === 440) {
      //   /* Vue.toasted.global.error({
      //     message: 'Sessão expirada. Faça o login novamente!'
      //   })
      //   store.dispatch('login/logout') */
      // }
      store.dispatch('loading/stop')
      return Promise.reject(error)
    }
  )

  return api
}

export default api
