<template>
  <div />
</template>

<script>
export default {
  props: {
    rules: { type: Array }
  },
  data: () => ({
    error: '',
    valid: true
  }),
  methods: {
    validate (value) {
      let result = true
      if (this.rules) {
        for (let index = 0; index < this.rules.length; index++) {
          result = this.rules[index](value)
          if (result !== true) {
            this.error = result
            this.valid = false
            break
          } else {
            this.resetValidation()
          }
        }
      }
      return result

      /* this.rules.forEach(r => {
        result = r(value)
      }) */
    },
    resetValidation () {
      this.error = ''
      this.valid = true
    }
  }
}
</script>

<style>

</style>
