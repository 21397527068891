<template>
  <div>
    <kosmo-input
      :key="isBrazilian"
      :label="labelDocument"
      :value="value"
      :rules="rulesDocument"
      :mask="isBrazilian ? '###.###.###-##' : null"
      :disabled="disabled"
      placeholder="Somente números"
      @input="value => $emit('input', value)"
      @blur="blur"
    />
  </div>
</template>

<script>
import KosmoRules from './KosmoRules'
export default {
  mixins: [KosmoRules],
  props: {
    value: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Array,
      default: () => []
    },
    nationality: {
      type: String,
      default: 'BRASILEIRA'
    }
  },
  computed: {
    labelDocument () {
      return this.isBrazilian ? 'CPF' : 'Documento'
    },
    rulesDocument () {
      return this.isBrazilian ? [this.$rules.cpf] : [this.$rules.required, this.$rules.document]
    },
    isBrazilian () {
      return this.nationality === 'BRASILEIRA'
    }
  },
  watch: {
    value (value) {
      if (value && value.length === 11 && this.isBrazilian) {
        this.$emit('validate')
      }
    }
  },
  methods: {
    blur () {
      if (this.value && this.nationality !== 'BRASILEIRA') this.$emit('validate')
    }
  }
}
</script>

<style>
</style>
