/* import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'

import './app'
import { min } from './ekko-lightbox.min.js'
 */

import Vue from 'vue'
import VueTheMask from 'vue-the-mask'
import VueSweetalert2 from 'vue-sweetalert2'
// import './quasar/quasar'
import './bootstrap/index'
import './scroll'
import 'sweetalert2/dist/sweetalert2.min.css'
import VueInfiniteScrol from 'vue-infinite-scroll'

Vue.use(VueSweetalert2)
Vue.use(VueTheMask)
Vue.use(VueInfiniteScrol)
