import router from '@/router'

const state = {
}

const mutations = {
}

const actions = {
  goSubscriptionPage ({ rootGetters }) {
    if (rootGetters['auth/isLogged']) {
      router.push({ name: 'auth.subscriptionLogged' })
    } else {
      router.push({ name: 'app.subscription' })
    }
  }
}

const getters = {
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
