const meta = {
  requiresAuth: true
}

const routes = [
  {
    path: '/',
    name: 'app',
    component: () => import('./Layout'),
    redirect: to => ({ ...to, name: 'app.home' }),
    meta,
    children: [
      {
        path: '/home',
        name: 'app.home',
        component: () => import('@/views/app/Home'),
        visible: true
      },
      {
        path: '/inscricao',
        name: 'app.subscription',
        component: () => import('@/views/app/Subscription'),
        visible: true,
        props: {
          text: 'Inscrição'
        }
      },
      {
        path: '/informacoes',
        name: 'app.information',
        component: () => import('@/views/app/Informations'),
        visible: true,
        props: {
          text: 'Informações'
        }
      },
      {
        path: '/dicas',
        name: 'app.tips',
        component: () => import('@/views/app/Tips'),
        visible: true,
        props: {
          text: 'Dicas'
        }
      },
      {
        path: '/regulamento',
        name: 'app.regulation',
        component: () => import('@/views/app/Regulation'),
        visible: true,
        props: {
          text: 'Regulamento'
        }
      },
      {
        path: '/instituicoes',
        name: 'app.institutions',
        component: () => import('@/views/app/Institutions'),
        visible: true,
        props: {
          text: 'Instituições'
        }
      },
      {
        path: '/pagamento',
        name: 'app.payment',
        component: () => import('@/views/app/Payment'),
        visible: false,
        props: {
          text: 'Pagamento da Inscrição'
        }
      },
      {
        path: '/contato',
        name: 'app.contact',
        component: () => import('@/views/app/Contact'),
        visible: true,
        props: {
          text: 'Contato'
        }
      },
      {
        path: '/faq',
        name: 'app.faq',
        component: () => import('@/views/app/Faqs'),
        visible: true,
        props: {
          text: 'FAQ'
        }
      },
      {
        path: '/percurso',
        name: 'app.route',
        component: () => import('@/views/app/Route'),
        visible: true,
        props: {
          text: 'Percurso'
        }
      },
      {
        path: '/kit',
        name: 'app.kit',
        component: () => import('@/views/app/Kit'),
        visible: true,
        props: {
          text: 'Kit'
        }
      },
      {
        path: '/resultado',
        name: 'app.result',
        component: () => import('@/views/app/Result'),
        visible: true,
        props: {
          text: 'Resultado'
        }
      },
      {
        path: '/fotos',
        name: 'app.photos',
        component: () => import('@/views/app/Photos'),
        visible: true,
        props: {
          text: 'Fotos'
        }
      },
      {
        path: '/noticias',
        name: 'app.news',
        component: () => import('@/views/app/News'),
        visible: true,
        props: {
          text: 'Notícias'
        }
      },
      {
        path: '/noticias/:id',
        name: 'app.theNews',
        component: () => import('@/views/app/TheNews'),
        visible: true,
        props: {
          text: 'Notícia'
        }
      }
    ]
  }
]

export default routes
