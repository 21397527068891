import Vue from 'vue'
import VueGtag from 'vue-gtag'
import App from './App.vue'
import router from './router'
import store from './store'

import './plugins'
import './styles'
import './components'
import './misc/rules'
import './misc/filters'
import './misc/error-handler'

Vue.config.productionTip = false

Vue.use(VueGtag, {
  config: {
    id: 'G-2GTNR6SXRS'
  }
}, router)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
