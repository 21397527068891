import Vue from 'vue'
import Router from 'vue-router'
import authRoutes from '@/layouts/app/auth-routes.js'
import appRoutes from '@/layouts/app/routes.js'
import store from '@/store'

Vue.use(Router)

const router = new Router({
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [...authRoutes, ...appRoutes]
})

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth && !store.getters['auth/isLogged']) {
    store.dispatch('auth/logout')
    next({ name: 'auth.login' })
  /* } else if (from.name === 'app.subscription' && to.name === 'auth.login' && store.state.subscription.usePreviousData && store.getters['auth/isLogged']) {
    next({ name: 'auth.subscriptionLogged' }) */
  } else if (to.name === 'auth.login' && store.getters['auth/isLogged']) {
    next({ name: 'auth.dashboard' })
  } else {
    next(next)
  }
})

export default router
