const meta = {
  requiresAuth: true
}

const routes = [
  {
    path: '/auth',
    name: 'auth',
    component: () => import('./Layout'),
    redirect: to => ({ ...to, name: 'auth.login' }),
    children: [
      {
        path: 'login',
        name: 'auth.login',
        component: () => import('@/views/auth/Login'),
        visible: true,
        props: {
          text: 'Área do inscrito'
        }
      },
      {
        meta,
        path: 'dashboard',
        name: 'auth.dashboard',
        component: () => import('@/views/auth/Dashboard'),
        visible: true,
        props: {
          text: 'Minhas inscrições'
        }
      },
      {
        path: 'recuperar-senha',
        name: 'auth.recoverPassword',
        component: () => import('@/views/auth/RecoverPassword'),
        visible: true,
        props: {
          text: 'Minhas inscrições'
        }
      },
      {
        path: 'recuperar-email',
        name: 'auth.recoverEmail',
        component: () => import('@/views/auth/RecoverEmail'),
        visible: true,
        props: {
          text: 'Minhas inscrições'
        }
      },
      {
        path: 'inscricao',
        name: 'auth.subscriptionLogged',
        component: () => import('@/views/auth/SubscriptionLogged'),
        visible: true,
        props: {
          text: 'Nova Inscrição'
        }
      }

    ]
  }
]

export default routes
