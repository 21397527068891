const state = {
  token: JSON.parse(window.sessionStorage.getItem('cassems_corrida_public_token')),
  loggedUser: JSON.parse(window.sessionStorage.getItem('cassems_corrida_logged_user'))
}

const mutations = {
  login (state, { token, user }) {
    window.sessionStorage.setItem('cassems_corrida_public_token', JSON.stringify(token))
    window.sessionStorage.setItem('cassems_corrida_logged_user', JSON.stringify(user))
    state.token = token
    state.loggedUser = user
  },

  logout (state) {
    window.sessionStorage.removeItem('cassems_corrida_public_token')
    window.sessionStorage.removeItem('cassems_corrida_logged_user')
    state.token = undefined
    state.loggedUser = undefined
  }
}

const actions = {
  async login (context, token) {
    await context.commit('login', token)
  },

  async logout (context) {
    await context.commit('logout')
  }
}

const getters = {
  isLogged (state) {
    return !!state.token
  },
  token (state) {
    return state.token
  },
  documentLoggedUser (state) {
    return state.loggedUser.document
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
