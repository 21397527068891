import api from './api-service'

const validateUserToSubscription = ({ document, nationality }) => {
  return api().get('users-subscriptions/validate-document', { document, nationality })
}

const validateUserActiveEvent = ({ document, nationality }) => {
  return api().get('users-subscriptions/validate-user-active-event?document=' + document + '&nationality=' + nationality)
}

const validateUserPreviousEvent = ({ document }) => {
  return api().get('users-subscriptions/validate-user-previous-event?document=' + document)
}

const validateUserBeneficiario = ({ document }) => {
  return api().get('users-subscriptions/validate-user-beneficiario?document=' + document)
}

const validateUserLoggedToSubscription = (document, nationality) => {
  return api().get('users-subscriptions/validate-user-logged', { document, nationality })
}

const findById = id => {
  return api().get('users-subscriptions/payment/' + id)
}

const create = (userSubscription) => {
  return api().post('users-subscriptions/public', userSubscription)
}

const createLogged = (userSubscription) => {
  return api().post('users-subscriptions/auth', userSubscription)
}

const getStatusAndPaymentByUser = () => {
  return api().get('users-subscriptions/status')
}

const getIdByUser = () => {
  return api().get('users-subscriptions/id/user')
}

const getByUser = () => {
  return api().get('users-subscriptions/user')
}

const generateReceipt = (userSubscriptionId) => {
  return api().get('users-subscriptions/receipt/' + userSubscriptionId, {
    responseType: 'blob'
  })
}

const getTermData = userSubscriptionId => api().get(`users-subscriptions/refund/${userSubscriptionId}/term`)

export {
  validateUserToSubscription, findById, create, getStatusAndPaymentByUser,
  getIdByUser, getByUser, generateReceipt, createLogged, validateUserLoggedToSubscription,
  validateUserActiveEvent, validateUserPreviousEvent, validateUserBeneficiario, getTermData
}
