<template>
  <div class="form-group">
    <form>
      <slot />
    </form>
  </div>
</template>

<script>
export default {
  methods: {
    validate () {
      let result = true
      this.$children.forEach(c => {
        if (c.rules && c.validate(c.value) !== true) {
          result = false
        }
      })
      return result
    },
    resetValidation () {
      this.$children.forEach(c => {
        if (c.rules && c.validate(c.value) !== true) {
          c.resetValidation()
        }
      })
    }
  }
}
</script>

<style>
</style>
