<template>
  <kosmo-select
    label="Nacionalidade *"
    :items="nationalities"
    :value="value"
    :rules="rules"
    :disabled="disabled"
    @input="value => $emit('input', value)"
  />
</template>

<script>
import KosmoRules from './KosmoRules'
export default {
  mixins: [KosmoRules],
  props: {
    rules: {
      type: Array,
      default: () => []
    },
    value: {
      type: String,
      default: 'BRASILEIRA'
    },
    items: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    nationalities: []
  }),
  created () {
    if (!this.items || this.items.length === 0) {
      this.loadNationalities()
    } else {
      this.nationalities = this.items
    }
    this.$emit('input', this.value)
  },
  methods: {
    async loadNationalities () {
      this.nationalities = ['BRASILEIRA', 'OUTRAS']
    }
  }
}
</script>

<style>
</style>
